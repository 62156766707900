.click-area {
  button {
    border: 0;
    line-height: 4;
    padding: 0% 15%;
    width: 100%;
    height: 300px;
    text-align: center;
    color: rgb(39, 39, 39);
    background-color: #fff;
    font-size: 1.3em;
    &:hover {
      background-color: rgb(245, 244, 244);
    }
  }
}
