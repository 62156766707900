.right-sidebar {
  .card {
    .card-header {
      background: white;
    }
    .card-body {
      .btn {
        display: block;
        margin-bottom: 1rem;
        background: white;
        border: none;
        -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.19);
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.19);
        color: black;
        &:hover {
          background: rgb(226, 226, 226);
        }
      }
    }
  }
}
