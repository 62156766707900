.result-area {
  text-align: center;
  .card {
    padding: 0.6rem;
    background: #536976;
    background: -webkit-linear-gradient(to right, #292e49, #536976);
    background: linear-gradient(to right, #292e49, #536976);

    width: 100%;
    height: auto;
    .count {
      color: white;
      font-size: 2em;
    }
    .label {
      color: white;
      font-size: 1em;
    }
  }
}
