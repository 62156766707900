.result-modal {
  &.modal {
  }
  .modal-dialog {
    max-width: 1000px;
    .modal-content {
      .modal-body {
        img {
          width: 60%;
          height: auto;
          margin-left: 4rem;
        }
        .result-section {
          .result {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
            }
            p {
              font-size: 1.2em;
            }
          }
        }
        .quote-section {
          margin: 3rem;
          h2 {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
