.navbar {
  box-shadow: 0 4px 32px 0 rgba(10, 14, 29, 0.02),
    0 8px 64px 0 rgba(10, 14, 29, 0.08);
  background: white;
  .navbar-brand {
    color: black;
    &:hover {
      color: rgb(70, 70, 70);
    }
  }
  .nav-item {
    border-radius: 0.2rem;
    margin: 0 0.3rem;
    position: relative;
    &:hover {
      -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.19);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.19);
    }
    &.active {
      background: rgb(226, 226, 226);
    }
    .nav-link {
      color: #343a40 !important;
    }
    .mouse-clicker-options {
      position: absolute;
      background: white;
      left: 0rem;
      min-width: 15rem;
      min-height: 18rem;
      border-radius: 0.2rem;
      border: 1px solid rgb(212, 211, 211);
      .nav-item {
        margin: 0.6rem 0.6rem;
      }
    }
  }
}
